<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<style type="text/css" scoped>
  /deep/.el-input__inner {
    height: 30px;
    line-height: 30px;
    border-color: rgb(23, 118, 210);
  }

  /deep/.el-select .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }

  .msgLabel {
    height: 40px;
    line-height: 40px;
  }

  .msgName {
    min-width: 125px;
    width: 125px;
  }

  .msgVal {
    padding-right: 10px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import {
    getRefund,
    addRefund,
    editRefund,
    deleteRefund,
    getEasy
  } from "@/api/pay/refund.js"
  import {
    getExamEasy,
  } from "@/api/admin/exam/examRecord.js"
  /**
   * 收费统计
   */
  export default {
    components: {
      Layout,
      PageHeader,



    },
    data() {
      return {
        title: "退费项目查询 ",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "退费项目",
            active: true
          }
        ],
        exportTitle: "退费管理导出",
        module: "TFGL",
        tableList: [],
        showRefund: false,
        dialogTitle: "",
        addForm: {},
        examList: [],
        years: [],
        tradList: [],
        sid: "",
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          nf: new Date().getFullYear(),
          ksbmbh: "",
          kssj: "",
          jssj: "",
          keyword: ""
        }

      };

    },
    methods: {
      //获取当前年份开始的前后几年
      getYear() { //获取年份，当前年前后几年
        var y = new Date().getFullYear();
        for (var i = 0; i <= 5; i++) {
          if (i < 5) {
            this.years.unshift({
              value: (y - i),
              label: (y - i)
            })
          } else {
            for (var x = 1; x < 3; x++) {
              this.years.push({
                value: (y + x),
                label: (y + x)
              })
            }
          }
        }
      },
      // 获取考试
      getExamList() {
        getExamEasy({
          ksnf: this.pageData.nf
        }).then(res => {
          if (res.status) {
            this.examList = res.data
          }
        })
      },
      searchClick() { //搜索时先将分页的页码归1
        this.pageData.pageNum = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      // 列表
      getList() {
        getRefund(this.pageData).then(res => {
          if (res.status) {
            if (res.status) {
              this.tableList = res.data
              this.pageData.total = res.total

            }
          }
        })
      },
      //添加弹窗
      addItem() {
        this.showRefund = true
        this.dialogTitle = "添加退费记录"
        this.addForm = {}
      },
      // 修改弹窗
      editItem(data) {
        this.showRefund = true
        this.dialogTitle = "修改退费记录"
        let copyData = JSON.parse(JSON.stringify(data))
        this.addForm = copyData
        this.addForm.ksbmbh = copyData.ssdm
        this.addForm.zffs = copyData.jffs
        this.addForm.ddzje = copyData.zje / 100
        this.addForm.ddjyh = copyData.jyh
        this.addForm.tfzje = copyData.tfzje / 100
        this.$nextTick(() => {
          this.getPersonTrad()

        })

      },
      // 删除
      deleteItem(obj) {
        this.$confirm(`此操作将永久删除该【${obj.xm}】的退费数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteRefund(obj.sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 添加退费
      addRefund() {
        let formDate = JSON.parse(JSON.stringify(this.addForm))
        formDate.tfzje = this.addForm.tfzje * 100
        addRefund(this.sid, formDate).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: res.message
            });
            this.showRefund = false
            this.getList()
          }
        })
      },
      // 修改退费
      editRefund() {
        let formDate = JSON.parse(JSON.stringify(this.addForm))
        formDate.sid = this.addForm.sid
        formDate.tfzje = this.addForm.tfzje * 100
        editRefund(formDate).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: res.message
            });
            this.showRefund = false
            this.getList()
          }
        })
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      submit() {
        if (this.addForm.sid) {
          this.editRefund()
        } else {
          this.addRefund()
        }
      },
      // 获取考试名称
      getKsmc() {
        let val = this.addForm.ksbmbh
        let obj = this.examList.find(k => {
          return k.ksbmbh === val
        })
        this.addForm.ksmc = obj.ksmc
        this.getPersonTrad()

      },
      // 查询个人报名缴费订单
      getPersonTrad() {
        let formData = {
          ksbmbh: this.addForm.ksbmbh,
          xm: this.addForm.xm,
          sfzjh: this.addForm.sfzjh
        }
        if (formData) {
          return getEasy(formData).then(res => {
            if (res.status) {
              this.tradList = res.data
            }
          })
        }
      },
      // 选择交易后触发
      getTradsid() {
        let val = this.addForm.ddh
        let obj = this.tradList.find(k => {
          return k.ddh === val
        })
        this.sid = obj.sid
        this.addForm.ddzje = obj.ddzje
        this.addForm.zffs = obj.zffs
        this.addForm.ddjyh = obj.ddjyh

      }
    },
    mounted() {
      this.getList()
      this.getYear()
      this.getExamList()
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 check-table-top">
              <div class="flexList">
                <el-select class=" mr-2 " style="width:8%;" clearable placeholder="年份" v-model="pageData.nf"
                  size="small">
                  <el-option v-for="(item, i) in years" :label="item.label" :value="item.value" :key="i">
                  </el-option>
                </el-select>
                <el-select class=" mr-2 " style="width: 10%;" clearable placeholder="请选择考试项目" size="small"
                  v-model="pageData.ksbmbh">
                  <el-option v-for="(item, i) in examList" :label="item.ksmc" :value="item.ksbmbh" :key="i"></el-option>
                </el-select>
                <el-date-picker v-model="pageData.kssj" type="datetime" size="small" style="width: 16%;" class="mr-2 "
                  placeholder="开始日期" @change="pageData.kssj=formatDateDetails(new Date($event))">
                </el-date-picker>
                <el-date-picker v-model="pageData.jssj" type="datetime" size="small" style="width: 16%;" class="mr-2 "
                  placeholder="结束日期" @change="pageData.jssj=formatDateDetails(new Date($event))">
                </el-date-picker>
                <input placeholder="输入关键字" class="h30 form-control border-blue mr-2 w-10" v-model="pageData.keyword"
                  maxlength="50" />
                <button type="button" class="btn btn-info h30 flexList mr-2 w-sm " @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <b-button @click="addItem" variant="outline-info" class="flexList mr-2 condition"><i
                    class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>新增退费</b-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title:exportTitle, type: 'xlsx', module:module,condition:pageData})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type:'dbf', module:module,condition:pageData})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width:3%;"> 序号 </th>
                    <th>考试名称</th>
                    <th style="width: 5%;">姓名 </th>
                    <th style="width: 12%;">证件号码 </th>
                    <th style="width: 8%;">交易序号 </th>
                    <th style="width: 8%;">交易方式</th>
                    <th style="width: 5%;">交易金额</th>
                    <th style="width:5%;">退费金额</th>
                    <th style="width: 8%;">退费原因</th>
                    <th style="width: 5%;">操作人</th>
                    <th style="width: 11%;">退费时间</th>
                    <th style="width: 5%;">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{obj.ssmc}}</td>
                    <td>{{obj.xm}}</td>
                    <td>{{obj.sfzjh}}</td>
                    <td>{{obj.jyh}}</td>
                    <td>
                      <div class="line1" v-if="obj.jffs=='01'">微信支付</div>
                      <div class="line1" v-if="obj.jffs=='02'">支付宝支付</div>
                      <div class="line1" v-if="obj.jffs=='03'">易宝支付</div>
                      <div class="line1" v-if="obj.jffs=='04'">线下支付_现金</div>
                      <div class="line1" v-if="obj.jffs=='06'">线下支付_单位</div>
                      <div class="line1" v-if="obj.jffs=='05'">减免</div>
                    </td>
                    <td>{{obj.zje/100}}</td>
                    <td>{{obj.tfzje/100}}</td>
                    <td>{{obj.tfly}}</td>
                    <td>{{obj.createUser}}</td>
                    <td>{{obj.tfsj}}</td>
                    <td class="tab-icon"><i class="iconfont icon-edit-two align-middle mr-1"
                        @click='editItem(obj)'></i><i class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                        @click="deleteItem(obj)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <b-modal v-model="showRefund" centered :title="this.dialogTitle" size="lg" title-class="font-18" hide-footer>
      <form>
        <div class="flexList fs-xs w-100">
          <div class=" msgContent flexList w-100" style="flex-wrap: wrap;">
            <div class="flexList w-50 msgLabel">
              <div class="msgName label-required">选择年份</div>
              <div class="msgVal flexList">
                <select class="form-control  form-select" v-model="addForm.nf" @change="getExamList">
                  <option>年份 </option>
                  <option v-for="(item, i) in years" :value="item.value" :key="i">{{item.value}}</option>
                </select>
              </div>
            </div>
            <div class="flexList w-50 msgLabel">
              <div class="msgName label-required">选择报名</div>
              <div class="msgVal flexList">
                <select class="form-control form-select " v-model="addForm.ksbmbh" @change="getKsmc">
                  <option>请选择考试 </option>
                  <option v-for="(item, i) in examList" :value="item.ksbmbh" :label="item.ksmc" :key="i">{{item.ksmc}}
                  </option>

                </select>
              </div>
            </div>
            <div class="flexList w-50 msgLabel">
              <div class="msgName label-required">考生姓名</div>
              <div class="msgVal flexList">
                <input type="text" name="" id="" class="form-control h30" value="" placeholder="请输入姓名"
                  v-model="addForm.xm" />
              </div>
            </div>
            <div class="flexList w-50 msgLabel">
              <div class="msgName label-required">身份证号</div>
              <div class="msgVal flexList">
                <input type="text" name="" id="" class="form-control h30" value="" placeholder="请输入身份证号"
                  v-model="addForm.sfzjh" @change="getPersonTrad" />
              </div>
            </div>
            <div class="flexList w-100 msgLabel">
              <div class="msgName label-required">交易记录</div>
              <div class="msgVal flexList">
                <select name="" class="form-control form-select w-60" v-model="addForm.ddh" @change="getTradsid">
                  <option value="" disabled>
                    请选择交易记录
                  </option>
                  <option v-for="(item,i) in tradList" :key="i" :value="item.ddh">{{item.ddmc}}</option>
                </select>
              </div>
            </div>
            <div class="flexList w-50 msgLabel">
              <div class="msgName label-required">交易序号</div>
              <div class="msgVal flexList">
                <div class="line1">{{addForm.ddh}}</div>
              </div>
            </div>
            <div class="flexList w-50 msgLabel">
              <div class="msgName label-required">交易金额</div>
              <div class="msgVal flexList">
                <div class="line1">{{addForm.ddzje/100}}</div>
              </div>
            </div>
            <div class="flexList w-50 msgLabel">
              <div class="msgName label-required">交易方式</div>
              <div class="msgVal flexList">
                <div class="line1" v-if="addForm.zffs=='01'">微信支付</div>
                <div class="line1" v-if="addForm.zffs=='02'">支付宝支付</div>
                <div class="line1" v-if="addForm.zffs=='03'">易宝支付</div>
                <div class="line1" v-if="addForm.zffs=='04'">线下支付_现金</div>
                <div class="line1" v-if="addForm.zffs=='06'">线下支付_单位</div>
                <div class="line1" v-if="addForm.zffs=='05'">减免</div>
              </div>
            </div>
            <div class="flexList w-50 msgLabel">
              <div class="msgName label-required">交易单号</div>
              <div class="msgVal flexList">
                <div class="line1">{{addForm.ddjyh}}</div>
              </div>
            </div>
            <div class="flexList w-50 msgLabel">
              <div class="msgName label-required">退费理由</div>
              <div class="msgVal flexList">
                <select name="" class="form-control form-select" v-model="addForm.tfly">
                  <option value="">请选择原因</option>
                  <option value="重复支付">重复支付 </option>
                  <option value="系统错误">系统错误</option>
                  <option value="申请减免">申请减免</option>
                  <option value="其他">其他</option>
                </select>
              </div>
            </div>
            <div class="flexList w-50 msgLabel">
              <div class="msgName label-required">退费金额（元）</div>
              <div class="msgVal flexList">
                <input type="text" name="" id="" class="form-control h30" value="" placeholder="请输入退费金额"
                  v-model="addForm.tfzje" />
              </div>
            </div>
            <div class="flexList w-50 msgLabel">
              <div class="msgName label-required">退费方式</div>
              <div class="msgVal flexList">
                <select name="" class="form-control form-select" v-model="addForm.tffs">
                  <option value="">请选择退费方式</option>
                  <option value="01">微信</option>
                  <option value="02">支付宝</option>
                  <option value="03">易宝</option>
                  <option value="04">现金退款</option>
                  <option value="05">其他退款</option>
                  <option value="06">银行卡退款</option>
                </select>
              </div>
            </div>
            <div class="flexList w-50 msgLabel">
              <div class="msgName label-required">联系电话</div>
              <div class="msgVal flexList">
                <input type="text" name="" id="" class="form-control h30" value="" placeholder="请输入联系电话"
                  v-model="addForm.lxdy" />
              </div>
            </div>
            <div class="flexList w-100 msgLabel">
              <div class="msgName label-required">备注说明</div>
              <div class="msgVal flexList">
                <textarea rows="1" class="form-control w-100" cols="" maxlength="100" placeholder="可以是支付原因说明等备注，100字以内"
                  v-model="addForm.tfbz"></textarea>
              </div>
            </div>


          </div>
        </div>
      </form>

      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="submit">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showRefund = false">取消</button>
      </div>
    </b-modal>


    <!-- 弹窗结束 -->
  </Layout>
</template>
