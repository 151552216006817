// 退费

import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "@/libs/axios";
// 退费分页查询
export const getRefund = params => {
  return getRequest("/yethan/pay/feeRefund/listPage", params);
};
// 添加
export function addRefund(sid,params){
  return postRequest("/yethan/pay/feeRefund/order/"+sid,params);
}
// 修改
export const editRefund = params => {
  return putRequest("/yethan/pay/feeRefund", params);
};
// 删除
export function deleteRefund(sid) {
  return deleteRequest("/yethan/pay/feeRefund/" + sid);
}
// 查询个人报名缴费订单
export const getEasy = params => {
  return getRequest("/yethan/pay/trade/easyList", params);
};

